import "./App.scss";
import { useState, useEffect } from "react";
import NavHeader from "./components/NavHeader";
import { Route, Switch } from "react-router-dom";
import MainPage from "./content/MainPage/MainPage";
import { useBPName } from "src/common/useBPName";
import { UT30QradarSiem } from "src/constants/defaults"; // 5000
import BusinessPartner from "src/components/BusinessPartner/BusinessPartner";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import countryList from "src/data/currencies.json";
import { UseLang } from "src/common/useLang";

function App() {
  const { bpName, logo, companyId, desc, mssp, params } = useBPName(
    UT30QradarSiem,
    false
  );

  const [, setCurrency] = useState("");
  const [, setCountry] = useState("");
  const [lang, setLang] = useState("");
  const [urxlang, setURXlang] = useState("");
  const countryToTest = "us";
  const test = false;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [demo, setDemo] = useState("");
  const [closeModal, setCloseModal] = useState(false);
  const [runningMobile] = useState(isMobile);
  const { t /* i18n */ } = useTranslation();

  useEffect(() => {
    console.log(`Running mobile: `, runningMobile);
  }, [runningMobile]);

  useEffect(() => {
    const [lang, userLocale, country, currency, ddo] = UseLang(
      countryList,
      test,
      countryToTest,
      setCurrency,
      setCountry
    );
    setCurrency(currency);
    setCountry(country);
    setLang(ddo);
    setURXlang(lang);
    console.log(lang, userLocale, currency, country, urxlang);
  }, [test, urxlang]);

  return (
    <div className="App app-wrappe">
      <div className={runningMobile ? "message-mobile" : "demo-mobile"}>
        <p className="type2">{t("landing_text.orentation")}</p>
      </div>
      <div className={runningMobile ? "demo-mobile" : "demo-laptop"}>
        <NavHeader
          bpName={bpName}
          logo={logo}
          companyId={companyId}
          desc={desc}
          mssp={mssp}
          params={params}
          lang={lang}
          urxlang={urxlang}
          setDrawerOpen={setDrawerOpen}
          drawerOpen={drawerOpen}
        />
        {bpName ? (
          <>
            <BusinessPartner bpName={bpName} logo={logo} desc={desc} />
          </>
        ) : null}
        {/* <Content className="app-wrapper"> */}
        <Switch>
          <Route exact path="/">
            <MainPage
              bpName={bpName}
              runningMobile={runningMobile}
              urxlang={urxlang}
              setDrawerOpen={setDrawerOpen}
              drawerOpen={drawerOpen}
              demo={demo}
              setDemo={setDemo}
              closeModal={closeModal}
              setCloseModal={setCloseModal}
            />
          </Route>
          {/* Routes with language selection */}
          <Route exact path="/:lng">
            <MainPage
              bpName={bpName}
              runningMobile={runningMobile}
              urxlang={urxlang}
              setDrawerOpen={setDrawerOpen}
              drawerOpen={drawerOpen}
              demo={demo}
              setDemo={setDemo}
              closeModal={closeModal}
              setCloseModal={setCloseModal}
            />
          </Route>
        </Switch>
        {/* </Content> */}
      </div>
    </div>
  );
}

export default App;
