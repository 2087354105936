export const limits = {
  min: 300,
  max: 50000,
  secs: 1,
};

// UT30 is an unique code to identify each widget in the BPPR ecosystem
export const UT30MasPE = '30BH6';
export const UT30InstanaPE = '30AO8';
// UT30 is an unique code to identify each widget in the BPPR ecosystem
export const UT30QradarSiem = '30BHY';
