import { Component } from "react";
import { withTranslation } from "react-i18next";
import { DSH_Figma_Utils } from "../../common/figmaListener";
import { segmentTracking, productTitle } from "../../lib/tracker";
import { getEnv } from "../../common/useEnv";
import { readBucket } from "src/common/readCOS";
import { LangDefaults } from "src/common/useLang";

const pageTitle = "QRadar SIEM Demo";

class MainPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s1: true,
      text: "click",
      iframeUrl: "",
      figmaKey: "",
      figmaToken: "",
    };
  }

  componentDidMount = () => {
    const track = "track1";
    const localeCode = this.props.urxlang;
    console.log(` userLanguage`, this.props.urxlang, localeCode);

    getEnv().then((res) => {
      this.setState({ figmaToken: res[2], figmaKey: res[3] });

      console.log(`****\n res:`, res);
      const bucket = res[1];
      this.setState({ figmaToken: res[2] });

      readBucket(bucket).then((response) => {
        const figmaTrackContent =
          response[0].track !== null ? response[0][track] : null;
        figmaTrackContent.map((obj) => {
          console.log(`Processing: `, obj.countryLang);
          if (obj.countryLang === localeCode) {
            console.log(`Found demo:`, obj.countryLang);
            this.setState({
              iframeUrl: obj.emailDemoUrl,
              figmaKey: obj.figmaKey,
            });
            return obj;
          }
          return "";
        });
        if (this.state.iframeUrl.length === 0) {
          figmaTrackContent.map((obj) => {
            console.log(`Processing default: `, LangDefaults.localCountryLang);
            if (obj.countryLang === LangDefaults.localCountryLang) {
              console.log(`Found demo:`, obj.countryLang);
              this.setState({
                iframeUrl: obj.emailDemoUrl,
                figmaKey: obj.figmaKey,
              });
              return obj;
            }
            return "";
          });
        }
        DSH_Figma_Utils.init({
          figma_fileKey: this.state.figmaKey,
          figma_accessToken: this.state.figmaToken,
          metricsTracking: segmentTracking,
          clickCallback: null,
          product_title: productTitle,
          page_title: pageTitle,
        }); //the second param is the tracking function that needs to be called
      });
    });
  };

  render() {
    const { runningMobile /* i18n */ } = this.props;
    return (
      <div id="main-page">
        <div className="section">
          {this.props.bpName ? (
            <>
              <div className="bx--row">
                <div className="bx--col figma_top"></div>
              </div>
            </>
          ) : null}
          <div className="bx--row figma_div">
            <iframe
              tabIndex={"-1"}
              onLoad={() => {
                window.parent.focus();
              }}
              className={runningMobile ? "figma_frame1_mobile" : "figma_frame"}
              title={pageTitle}
              id="figma_demo"
              src={this.state.iframeUrl}
            ></iframe>
          </div>
          <div className="bx--row">
            <div className="bx--col figma_bottom1"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(MainPage);
