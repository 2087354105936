import { useState, useEffect } from "react";
import {
  Header,
  HeaderName,
  HeaderNavigation,
  HeaderGlobalBar,
  SkipToContent,
} from "carbon-components-react/es/components/UIShell";
import { ArrowRight16, Download16 } from "@carbon/icons-react";
import { Button, Loading } from "carbon-components-react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { segmentTracking } from "../../lib/tracker";
import { useCookiesURL } from "src/lib/UseCookiesUrl";
import Cookies from "js-cookie";
import * as URX_FORM from "../../constants/urx-form";
import { initUrxForm } from "src/common/loadUrxForm";
import { getEnv } from "../../common/useEnv";
import FormModal from "../UrxForm/FormModal";
import Helmet from "react-helmet";
import { urxScriptLoader, urxScript } from "src/common/globalDefinitions";

const pageTitle = "QRadar SIEM Demo";

const NavHeader = ({
  bpName,
  logo,
  companyId,
  desc,
  mssp,
  params,
  lang,
  urxlang,
  setDrawerOpen,
  drawerOpen,
}) => {
  const { t /* i18n */ } = useTranslation();
  const cookieURL = useCookiesURL();
  const [environment, setEnvironment] = useState("");
  const [formComplete, setFormComplete] = useState(false);
  const [loadIcon, setLoadIcon] = useState(false);

  window.onUrxFormSubmitSuccess = () => {
    setFormComplete(!formComplete);
    setDrawerOpen(false);
  };

  useEffect(() => {
    getEnv().then((res) => {
      console.log(`Environment:`, res[0]);
      setEnvironment(res[0]);
      return res[0];
    });
  }, []);

  const setCookie = () => {
    const getBp = () => {
      if (bpName) return bpName;
      if (logo) return companyId;
      if (desc) return desc;
      if (mssp) return mssp;
      const bpField = document.querySelector("#Q_BPNAME");
      return bpField ? bpField.value : "";
    };
    const text = JSON.stringify({
      source: URX_FORM.FORM_ID,
      // One field to provide all values (stringify)
      dcq: {
        Q_NOTESDATA:
          cookieURL +
          "Book a personal demo -QRadar SIEM demo-" +
          " | Referenced URL:" +
          window.location.href +
          " | FormId:" +
          URX_FORM.FORM_ID +
          (getBp() ? `|Preferred BP:${getBp()}` : ""),
        Q_ASSET_URL: window.location.href, // ISC - Asset URL
        Q_ASSET_NAME: "QRadar SIEM demo", // ISC - Asset Name
      },
    });
    Cookies.set("urxdcq", text);
    console.log("cookie", Cookies.get("urxdcq"));
  };

  const getProduct = () => {
    console.log(`parameter to send`, params);
    let url = "https://www.ibm.com/products/qradar-siem".concat(params);
    segmentTracking("CTA Clicked", {
      pageTitle: pageTitle,
      object: "Nav Header",
      resultValue: `Loaded: ${url}`,
      CTA: "Requested an URL",
      location: "Demo",
      action: "Clicked",
      field: "",
    });
    setCookie();
    setTimeout(async () => {
      window.open(url);
    }, 250);
  };

  const getVisitPricePage = () => {
    let url = "https://www.ibm.com/products/qradar-siem/pricing".concat(params);
    segmentTracking("CTA Clicked", {
      pageTitle: pageTitle,
      object: "Nav Header",
      resultValue: `Loaded: ${url}`,
      CTA: "Visit pricing page",
      location: "Demo",
      action: "Clicked",
      field: "",
    });
    setCookie();
    setTimeout(async () => {
      window.open(url);
    }, 250);
  };

  return (
    <div className="header">
      <Helmet>
        <script type="application/javascript" src={urxScriptLoader}></script>
        <script type="application/javascript" src={urxScript}></script>
      </Helmet>
      <Header aria-label="Client Center NavBar">
        <SkipToContent />
        <HeaderName
          element={Link}
          to={params}
          prefix="IBM"
          onClick={() => {
            getProduct();
          }}
        >
          <b>{t("Page.title")}</b>
        </HeaderName>
        <HeaderNavigation aria-label="App-Navbar">
          {/* <HeaderMenuItem element={Link} to={`/page-link`}>{t('NavHeader.8')}</HeaderMenuItem> */}
        </HeaderNavigation>
        <HeaderGlobalBar>
          <Button
            className="button-header"
            renderIcon={ArrowRight16}
            onClick={() => {
              getVisitPricePage();
            }}
          >
            {t("QradarPrice.title")}
          </Button>
          <Button
            className="button-header"
            renderIcon={loadIcon ? Download16 : ArrowRight16}
            onClick={async () => {
              console.log(`Load the form`);
              setLoadIcon(true);
              initUrxForm(
                environment,
                URX_FORM.INSTANCE_ID,
                URX_FORM.FORM_ID,
                urxlang,
                companyId
              );
              console.log(` After calling `, window.loadedUrxForm);
              segmentTracking("CTA Clicked", {
                pageTitle: pageTitle,
                object: "Nav Header",
                resultValue: "CTA clicked",
                CTA: "Book personal demo button",
                location: "Demo",
                action: "Clicked",
                field: "",
              });
              setTimeout(
                async () => {
                  setCookie();
                  setLoadIcon(false);
                  setDrawerOpen(true);
                },
                bpName ? 1000 : 10
              );
            }}
          >
            {t("requestDemo.title")}
          </Button>
        </HeaderGlobalBar>
      </Header>
      <div className="button-set">{loadIcon ? <Loading /> : null}</div>
      <FormModal
        bpName={bpName}
        logo={logo}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
      ></FormModal>
    </div>
  );
};

export default NavHeader;
